//-------------------------------------------------------------------------------------
// Angled Edges v2.0.0 (https://github.com/josephfusco/angled-edges)
// Copyright 2017 Joseph Fusco
// Licensed under MIT (https://github.com/josephfusco/angled-edges/blob/master/LICENSE)
//-------------------------------------------------------------------------------------

/// Replace `$search` with `$replace` in `$string`.
///
/// @author Hugo Giraudel
/// @link   http://www.sassmeister.com/gist/1b4f2da5527830088e4d
///
/// @param  {String}  $string - Initial string
/// @param  {String}  $search - Substring to replace
/// @param  {String}  $replace ('') - New value
/// @return {String}  Updated string
///
@function ae-str-replace($string, $search, $replace: '') {
	$index: str-index($string, $search);

	@if $index {
		@return str-slice($string, 1, $index - 1) + $replace + ae-str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}

	@return $string;
}

/// Encode SVG to use as background.
///
/// @param  {String}  $string
/// @return {String}  Encoded svg data
///
@function ae-svg-encode($string){
	$result: ae-str-replace($string, '<svg', '<svg xmlns="http://www.w3.org/2000/svg"');
	$result: ae-str-replace($result, '%', '%25');
	$result: ae-str-replace($result, '"', '\'');
	$result: ae-str-replace($result, '<', '%3C');
	$result: ae-str-replace($result, '>', '%3E');

	@return 'data:image/svg+xml,' + $result;
}

/// Outputs pseudo content for main mixin.
///
/// @author Joseph Fusco
///
/// @param  {String}  $location
/// @param  {Number}  $height
/// @output psuedo content
///
@mixin ae-pseudo($wedge, $height, $width) {
	background-image: url($wedge);
	background-position: center center;
	background-repeat: no-repeat;

	// full width wedge - needed as Firefox ignores preserveAspectRatio="none" in this case
	@if ($width == null) {
		background-size: 100% 100%;
	}

	content: '';
	height: $height * 1px;
	left: 0;
	position: absolute;
	right: 0;
	width: 100%;
	z-index: 1;
}

/// Attatches an svg wedge shape to an element.
///
/// @author Joseph Fusco
///
/// @param  {String}  $location   - 'inside top', 'outside top', 'inside bottom', 'outside bottom'
/// @param  {String}  $hypotenuse - 'upper left', 'upper right', 'lower left', 'lower right'
/// @param  {Color}   $fill
/// @param  {Number}  $height
/// @param  {Number}  $width
/// @output '::before' and/or '::after' with svg background image
///
@mixin angled-edge($location, $hypotenuse, $fill, $height: 100, $width: null) {

	position: relative;

	$points: (
		'upper left': '0,#{$height} #{$width},#{$height} #{$width},0',
		'upper right': '0,#{$height} #{$width},#{$height} 0,0',
		'lower left': '0,0 #{$width},#{$height} #{$width},0',
		'lower right': '0,0 #{$width},0 0,#{$height}'
	);

	// full width wedge
	@if ($width == null) {
		$points: (
			'upper left': '0,#{$height} 100,#{$height} 100,0',
			'upper right': '0,#{$height} 100,#{$height} 0,0',
			'lower left': '0,0 100,#{$height} 100,0',
			'lower right': '0,0 100,0 0,#{$height}'
		);
	}

	// ensure $fill color is using rgb()
	$fill-rgb: 'rgb(' + round(red($fill)) + ',' + round(green($fill)) + ',' + round(blue($fill)) + ')';

	// capture alpha component of $fill to use with fill-opacity
	$fill-alpha: alpha($fill);

	$wedge: '<svg width="#{$width}" height="#{$height}" fill="#{$fill-rgb}" fill-opacity="#{$fill-alpha}"><polygon points="#{map-get($points, $hypotenuse)}"></polygon></svg>';

	// full width wedge
	@if ($width == null) {
		$wedge: '<svg preserveAspectRatio="none" viewBox="0 0 100 #{$height}" fill="#{$fill-rgb}" fill-opacity="#{$fill-alpha}"><polygon points="#{map-get($points, $hypotenuse)}"></polygon></svg>';
	}

	$encoded-wedge: ae-svg-encode($wedge);

	@if ($location == 'inside top') {
		&::before {
			@include ae-pseudo($encoded-wedge, $height, $width);
			top: 0;
		}
	} @else if ($location == 'outside top') {
		&::before {
			@include ae-pseudo($encoded-wedge, $height, $width);
			top: -$height * 1px;
		}
	} @else if ($location == 'inside bottom') {
		&::after {
			@include ae-pseudo($encoded-wedge, $height, $width);
			bottom: 0;
		}
	} @else if ($location == 'outside bottom') {
		&::after {
			@include ae-pseudo($encoded-wedge, $height, $width);
			bottom: -$height * 1px;
		}
	} @else {
		@error 'Invalid argument for $location - must use: `inside top`, `outside top`, `inside bottom`, `outside bottom`';
	}

	@if (map-has-key($points, $hypotenuse) == false) {
		@error 'Invalid argument for $hypotenuse - must use: `upper left`, `upper right`, `lower left`, `lower right`';
	}
}
