/* --------------------------------
   site logo
-------------------------------- */

.site-logo
{
	display: inline-block;

	img
	{
		vertical-align: middle;
		max-width: 100%;
	}
}

@include media-breakpoint-up(sm)
{
	
}

@include media-breakpoint-up(md)
{
	
}

@include media-breakpoint-up(lg)
{
	
}

@include media-breakpoint-up(xl)
{
	
}