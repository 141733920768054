/* --------------------------------
   form
-------------------------------- */

form
{
	position: relative;

	.input-wrp
	{
		position: relative;
		display: block;
		width: 100%;
		line-height: 1;
		margin-top: 20px;
	}

	.textfield
	{
		display: block;
		float: none;
		width: 100%;
		background: none transparent;
		background-clip: padding-box;
		border: none;
		border-bottom: 1px solid #dadada;
		padding: 13px 0;
		line-height: 1.2;
		font:
		{
			size: rem-calc(14px);
			family: $base-fontFamily;
			weight: 500;
		};
		color: #666;
		appearance: none;
		outline: none;
		box-shadow: none;
		border-radius: 0;
		@include transition(
			background-color 0.3s ease-in-out,
			border-color 0.3s ease-in-out,
			color 0.3s ease-in-out
		);

		@include placeholder
		{
			color: #ccc;
			@include transition(color 0.3s ease-in-out);
		}

		&:hover,
		&:focus,
		&.hover,
		&.focus
		{
			// border-color: #9da5ad;
		}

		&.error { border-color: $primary-color !important; }
	}

	input.textfield,
	select.textfield,
	.nice-select.textfield
	{
		height: 44px;
	}

	select.js-select
	{
		width: 0;
		height: 0;
	}

	textarea
	{
		resize: vertical;
		min-height: 125px;
		height: 100%;
	}

	button[type=submit]
	{
		cursor: pointer;
		box-shadow: none;
		outline: none;
		margin-top: 55px;
	}
}

.form--horizontal
{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: flex-end;

	.input-wrp,
	button[type=submit]
	{
		margin-top: 0;
	}

	.input-wrp
	{
		flex: 1;
		padding-right: 20px;
	}
}

label { cursor: pointer; }

.checkfield
{
	position: relative;
	display: inline-block;
	margin-top: 20px;
	line-height: 1.2;
	padding-left: 30px;

	input[type="checkbox"]
	{
		position: absolute;
		top: 0;
		left: 0;
		width: 0;
		height: 0;
		visibility: hidden;
		opacity: 0;
		clip: rect(2px,2px,2px,2px);

		&:checked ~
		{
			i
			{
				background-color: $primary-color;
				border-color: $primary-color;

				&:before { transform: scale(1) rotate(45deg); }
			}

			span a { color: $primary-color; }
		}
	}

	i
	{
		position: relative;
		float: left;
		width: 14px;
		height: 14px;
		margin-top: 1px;
		margin-left: -30px;
		padding-left: 1px;
		background-color: $white-color;
		border: 1px solid #d6d6d6;
		font-size: rem-calc(16px);
		font-weight: 700;
		text-align: center;
		overflow: hidden;
		border-radius: 3px;
		@include transition(
			background-color 0.25s ease-in-out,
			border-color 0.25s ease-in-out
		);

		&:before
		{
			content: "";
			display: block;
			width: 5px;
			height: 7px;
			margin: auto;
			margin-top: 2px;
			border: 2px solid #333;
			border-top: none;
			border-left: none;
			transform: scale(0) rotate(45deg);
			@include transition(transform .25s cubic-bezier(.23,1,.32,1));
		}

		&:last-child { margin-right: 0; }
	}
}

.textfield.nice-select
{
	color: #ccc;
	padding-right: 30px;

	&:after
	{
		width: 7px;
		height: 7px;
		right: 5px;
		margin-top: -5px;
	}

	&.open,
	&:active,
	&:focus
	{
		border-color: #dadada;
	}

	.list
	{
		padding-top: 5px;
		padding-bottom: 5px;
		color: #888;
		box-shadow: 0 0 0 1px rgba(#000,.05);
	}

	.option
	{
		min-height: 35px;
		padding-left: 20px;
		line-height: 35px;

		&:hover,
		&.focus,
		&.selected.focus
		{
			background-color: #f7f7f7 !important;
		}

		&.selected
		{
			font-weight: 600;
		}
	}
}

@include media-breakpoint-up(sm)
{
	
}

@include media-breakpoint-up(md)
{
	
}

@include media-breakpoint-up(lg)
{
	
}

@include media-breakpoint-up(xl)
{
	
}