/* --------------------------------
   fontello
-------------------------------- */

[class^="fontello-"]:before,
[class*=" fontello-"]:before
{
	width: auto;
	margin-left: auto;
	margin-right: auto;
}